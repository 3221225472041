import { ReactComponent as Logo } from "assets/img/logo.svg"
import { SOCIAL, URL } from "libs/constants"
import { openLink } from "libs/functions"
import React from "react"
import { FaDiscord, FaTwitter } from "react-icons/fa"
import { Link } from "react-router-dom"

const Footer = () => {
  return (
    <>
      <div className="flex w-full justify-center px-20 sm:px-30">
        <div className="flex w-full max-w-screen-lg flex-wrap items-center justify-between gap-20 border-t-1 border-grey-border-33 py-26">
          <Link
            to={URL.HOME}
            className="flex w-full flex-wrap items-center justify-center gap-16 sm:w-auto sm:gap-30"
          >
            <Logo className="h-28" />
            <div className="text-14 font-bold text-grey-med">
              Apply for{" "}
              <span className="text-14 font-bold text-blue">Launchpad</span>
            </div>
          </Link>
          <div className="flex w-full items-center justify-center gap-18 sm:w-auto">
            <button
              onClick={(e) => openLink(e, SOCIAL.DISCORD)}
              className="group flex h-50 w-full items-center justify-center gap-8 rounded-4 border-2 border-grey-med hover:border-white-20 hover:bg-discord sm:w-[132px]"
            >
              <FaDiscord className="text-grey-med group-hover:text-white" />
              <span className="text-14 font-bold text-grey-med group-hover:text-white">
                Discord
              </span>
            </button>
            <div className="hidden h-20 border-r-1 border-grey-border sm:flex"></div>
            <button
              onClick={(e) => openLink(e, SOCIAL.TWITTER)}
              className="group flex h-50 w-full items-center justify-center gap-8 rounded-4 border-2 border-grey-med hover:border-white-20 hover:bg-twitter sm:w-[132px]"
            >
              <FaTwitter className="text-grey-med group-hover:text-white" />
              <span className="text-14 font-bold text-grey-med group-hover:text-white">
                Twitter
              </span>
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default Footer
