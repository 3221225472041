import { ReactComponent as ArrowLeft } from "assets/img/arrow_left.svg"
import { ReactComponent as ArrowRight } from "assets/img/arrow_right.svg"
import Banner2 from "assets/img/banner_2.png"
import Nft1 from "assets/img/nft1.png"
import Nft2 from "assets/img/nft2.png"
import Nft3 from "assets/img/nft3.png"
import { ReasonInterface } from "libs/interfaces"
import React, { useState } from "react"
import Wrapper from "utils/wrapper/Wrapper"

const HomeReasons = () => {
  const [reasons] = useState<ReasonInterface[]>([
    {
      title: "% Revenue from launchpad",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
    },
    {
      title: "Whitelist spots secured for some NFT holders for hyped projects",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
    },
    {
      title: "Whitelist spots secured for some NFT holders for hyped projects",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
    }
  ])

  return (
    <>
      <div className="relative mb-[110px] w-full">
        <img
          src={Banner2}
          alt=""
          className="absolute top-[50%] left-[50%] w-[1920px] max-w-[1920px] translate-x-[-50%] translate-y-[-50%] transform"
        />
        <div className="relative grid w-full grid-cols-1 gap-42">
          <div className="w-full text-center text-24 font-bold text-white">
            Why mint a LabMonke NFT?
          </div>
          <div className="grid w-full grid-cols-1 gap-50">
            {reasons.map((reason: ReasonInterface, key: number) => (
              <button
                className="relative grid grid-cols-[auto,1fr] gap-16 opacity-50 hover:opacity-100"
                key={key}
              >
                <Wrapper open={key < reasons.length - 1}>
                  <div className="absolute left-24 top-48 bottom-[-50px] border-r-1 border-dashed border-grey-border-dark"></div>
                </Wrapper>
                <div className="flex h-48 w-48 items-center justify-center rounded-full border-1 border-grey-border-dark bg-gradient-to-br from-grey-dark via-transparent to-grey-dark">
                  <span className="text-20 font-bold text-white">
                    {key + 1}
                  </span>
                </div>
                <div className="grid w-full grid-cols-1 gap-6 pt-8">
                  <div className="w-full text-left text-20 font-bold text-white">
                    {reason.title}
                  </div>
                  <div className="w-full text-left text-14 text-grey-med">
                    {reason.text}
                  </div>
                </div>
              </button>
            ))}
          </div>
        </div>
      </div>
      <div className="mb-[130px] w-full rounded-10 bg-grey-box p-16 sm:p-26">
        <div className="mb-8 w-full text-center text-24 font-bold text-white">
          Our NFTs
        </div>
        <div className="mb-26 w-full text-center text-14 text-grey-med">
          The most advanced Solana NFT builder, extensive image generation tools
          with a custom mint page.
        </div>
        <div className="mb-24 grid w-full grid-cols-1 gap-16 sm:grid-cols-3">
          <img src={Nft1} alt="" className="w-full" />
          <img src={Nft2} alt="" className="w-full" />
          <img src={Nft3} alt="" className="w-full" />
        </div>
        <div className="mb-[-50px] flex w-full justify-center">
          <div className="relative">
            <ArrowRight className="absolute top-40 left-[-20px] translate-x-[-100%] transform" />
            <ArrowLeft className="absolute top-40 right-[-20px] translate-x-[100%] transform" />
            <button className="relative flex h-50 w-[130px] items-center justify-center rounded-4 bg-blue">
              <span className="text-14 text-white">Mint NFTs</span>
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default HomeReasons
