import { URL } from "libs/constants"
import React from "react"
import { Route } from "react-router-dom"
import Home from "views/Home"
import NotFound from "views/NotFound"

export default [
  {
    component: (
      <Route exact path={URL.HOME} key={0}>
        <Home />
      </Route>
    )
  },
  {
    component: (
      <Route key={1}>
        <NotFound />
      </Route>
    )
  }
]
