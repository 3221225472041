import HomeQuestion from "components/home/HomeQuestion"
import { QuestionInterface } from "libs/interfaces"
import React, { useState } from "react"

const HomeFaq = () => {
  const [questions] = useState<QuestionInterface[]>([
    {
      title: "Have you developed projects before?",
      text: "Of course. We wanted to make sure our Launchpad experience was seamless before making it a revenue shared platform. Here are some of the many projects that have used Monkelabs in the past:"
    },
    {
      title: "Have you developed projects before?",
      text: "Of course. We wanted to make sure our Launchpad experience was seamless before making it a revenue shared platform. Here are some of the many projects that have used Monkelabs in the past:"
    },

    {
      title: "Have you developed projects before?",
      text: "Of course. We wanted to make sure our Launchpad experience was seamless before making it a revenue shared platform. Here are some of the many projects that have used Monkelabs in the past:"
    }
  ])

  return (
    <>
      <div className="w-full">
        <div className="mb-10 w-full text-center text-24 font-bold text-white">
          Frequently Asked Questions
        </div>
        <div className="mb-24 w-full text-center text-14 text-grey-med">
          The most advanced Solana NFT builder, extensive image generation tools
          with a custom mint page.
        </div>
        <div className="grid w-full grid-cols-1 gap-8">
          {questions.map((question: QuestionInterface, key: number) => (
            <HomeQuestion question={question} key={key} />
          ))}
        </div>
      </div>
    </>
  )
}

export default HomeFaq
