export const SERVER = process.env.REACT_APP_SERVER

export const URL = {
  HOME: "/"
}

export const REQ = {
  CALL: SERVER + "/call"
}

export const SOCIAL = {
  DISCORD: "",
  TWITTER: ""
}

export const DATE = {
  DATETIME: "MMM D YYYY, HH:mm",
  SHORT: "MMM D, YYYY"
}
