import { ReactComponent as Logo } from "assets/img/logo.svg"
import React from "react"

const Nav = () => {
  return (
    <>
      <div className="fixed top-0 left-0 flex w-full items-center justify-center bg-gradient-to-b from-grey to-transparent p-24">
        <Logo className="h-28" />
      </div>
    </>
  )
}

export default Nav
