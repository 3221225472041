import { QuestionInterface } from "libs/interfaces"
import React, { useEffect, useRef, useState } from "react"
import { MdRemove, MdAdd } from "react-icons/md"

const HomeQuestion = ({ question }: { question: QuestionInterface }) => {
  const ref = useRef<HTMLDivElement>(null)

  const [height, setHeight] = useState(0)
  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (ref.current) {
      setHeight(open ? ref.current.scrollHeight : 0)
    }
  }, [open])

  return (
    <>
      <button
        onClick={() => setOpen(!open)}
        className="rounded-10 bg-grey-box py-14 pl-26 pr-14"
      >
        <div className="grid w-full grid-cols-[1fr,auto] items-center">
          <div className="w-full text-left text-14 text-white">
            {question.title}
          </div>
          <div className="flex h-38 w-38 items-center justify-center rounded-4 bg-blue-dark-15">
            {open ? (
              <MdRemove className="text-20 text-blue" />
            ) : (
              <MdAdd className="text-20 text-blue" />
            )}
          </div>
        </div>
        <div
          ref={ref}
          style={{ maxHeight: `${height}px` }}
          className={`w-full overflow-hidden text-left text-14 text-grey-med transition-all duration-300 ${
            open ? "mt-14" : ""
          }`}
        >
          {question.text}
        </div>
      </button>
    </>
  )
}

export default HomeQuestion
