import Banner1 from "assets/img/banner_1.png"
import { ReactComponent as Line } from "assets/img/line.svg"
import { SOCIAL } from "libs/constants"
import { openLink } from "libs/functions"
import { PerkInterface } from "libs/interfaces"
import React, { useState } from "react"
import { BsImageAlt } from "react-icons/bs"
import { FaDiscord, FaPaintBrush, FaTwitter } from "react-icons/fa"
import { MdDashboard } from "react-icons/md"

const HomeTop = () => {
  const [perks] = useState<PerkInterface[]>([
    {
      icon: (
        <div className="flex h-44 w-44 items-center justify-center rounded-4 bg-gradient-to-br from-red via-red-gradient to-red">
          <BsImageAlt className="text-20 text-white" />
        </div>
      ),
      title: "Generative Images",
      text: "Use our Lab to upload a collection of assets and layers to be automatically generated for you. Each asset/layer uploaded can be given a trait name and rarity value that will be used to create the unique art set for your NFT launch."
    },
    {
      icon: (
        <div className="flex h-44 w-44 items-center justify-center rounded-4 bg-gradient-to-br from-green via-green-gradient to-green">
          <FaPaintBrush className="text-20 text-white" />
        </div>
      ),
      title: "Custom Mint Page",
      text: "Once your collection is ready, Monkelabs will generate an Arweave upload and deployment price for you. After paying, you can start customizing your mint page to your liking."
    },
    {
      icon: (
        <div className="flex h-44 w-44 items-center justify-center rounded-4 bg-gradient-to-br from-blue via-blue-gradient to-blue">
          <MdDashboard className="text-20 text-white" />
        </div>
      ),
      title: "Launch Dashboard",
      text: "Thats it! Monitor your NFT drop during launch using our dashboard, view owners, export hash lists and view all required information for secondary marketplaces. We've got you covered for all your post-mint needs."
    }
  ])

  return (
    <>
      <div className="relative mb-[100px] w-full">
        <img
          src={Banner1}
          alt=""
          className="absolute top-[50%] left-[50%] w-[1920px] max-w-[1920px] translate-x-[-50%] translate-y-[-50%] transform"
        />
        <div className="relative flex w-full justify-center">
          <div className="w-[600px]">
            <div className="sm:text-36 mb-26 w-full text-center text-32 font-bold text-white md:text-40">
              Revenue sharing Launchpad for{" "}
              <span className="relative text-32 font-bold text-white md:text-40">
                Solana
                <Line className="absolute left-0 bottom-0 w-full translate-y-[100%] transform" />
              </span>{" "}
              NFT projects.
            </div>
            <div className="mb-50 w-full text-center text-14 text-grey-med">
              Revenue sharing Launchpad for Solana NFT projects.
            </div>
            <div className="text-justify-center w-full">
              <div className="flex items-center justify-center gap-18">
                <button
                  onClick={(e) => openLink(e, SOCIAL.DISCORD)}
                  className="flex h-50 w-full items-center justify-center gap-8 rounded-4 border-2 border-discord bg-discord hover:border-white-20 sm:w-[132px]"
                >
                  <FaDiscord className="text-white" />
                  <span className="text-14 font-bold text-white">Discord</span>
                </button>
                <div className="hidden h-20 border-r-1 border-grey-border sm:flex"></div>
                <button
                  onClick={(e) => openLink(e, SOCIAL.TWITTER)}
                  className="flex h-50 w-full items-center justify-center gap-8 rounded-4 border-2 border-twitter bg-twitter hover:border-white-20 sm:w-[132px]"
                >
                  <FaTwitter className="text-white" />
                  <span className="text-14 font-bold text-white">Twitter</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mb-[78px] w-full rounded-10 bg-grey-bg-75 p-16 sm:p-24">
        <div className="mb-8 w-full text-center text-24 font-bold text-white">
          Launch your NFT project Today
        </div>
        <div className="mb-28 w-full text-center text-14 text-grey-med">
          The most advanced Solana NFT builder, extensive image generation tools
          with a custom mint page.
        </div>
        <div className="grid w-full grid-cols-1 gap-16 xl:grid-cols-3">
          {perks.map((perk: PerkInterface, key: number) => (
            <button
              className="bg-box-75 w-full rounded-10 bg-grey-box-75 p-22 hover:bg-grey-box"
              key={key}
            >
              <div className="mb-16 flex w-full justify-start">{perk.icon}</div>
              <div className="mb-12 w-full text-left text-18 font-bold text-white">
                {perk.title}
              </div>
              <div className="w-full text-left text-14 text-grey-med">
                {perk.text}
              </div>
            </button>
          ))}
        </div>
      </div>
    </>
  )
}

export default HomeTop
