import Footer from "components/footer/Footer"
import HomeFaq from "components/home/HomeFaq"
import HomeReasons from "components/home/HomeReasons"
import HomeTop from "components/home/HomeTop"
import Nav from "components/nav/Nav"
import React from "react"

const Home = () => {
  return (
    <>
      <Nav />
      <div className="mt-[70px] flex w-full justify-center py-[40px] px-12 xs:px-20 sm:py-[60px] sm:px-30 md:py-[100px]">
        <div className="w-full max-w-screen-lg">
          <HomeTop />
          <HomeReasons />
          <HomeFaq />
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Home
